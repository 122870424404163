#main-pagamentos {
  background-color: white;
  min-height: 100vh;
}

#pagamento-inf-paciente {
  padding: 32px;
}

#valor-total-pago {
  padding: 8px;
  text-align: center;
  background-color: var(--primary-color);
  color: white;
  font-size: 2rem;
}

#procedimentos {
  padding: 32px;
}
#header-pagamentos {
  display: flex;
  justify-content: space-between;
  padding: 32px;
  color: var(--primary-color);
}
#header-pagamentos h1 {
  color: var(--primary-color);
  font-size: 1.5rem;
}
#header-pagamentos img {
  height: 30% !important;
  width: 30% !important;
}
